import useAuth from "@cospex/client/hooks/useAuth";
import { useTitle } from "@cospex/client/hooks/useTitle";
import useTranslation from "@cospex/client/hooks/useTranslation";
import SocialProof from "@cospex/client/reverse/SocialProof";
import Pricing from "@cospex/client/reverse/components/Pricing";
import Advantage from "@cospex/client/reverse/components/sections/Advantage";
import Discover from "@cospex/client/reverse/components/sections/Discover";
import HeroWithSearch from "@cospex/client/reverse/components/sections/HeroWithSearch";
import HowDoesItWork from "@cospex/client/reverse/components/sections/HowDoesItWork";
import Reviews from "@cospex/client/reverse/components/sections/Reviews";
import UniqueTechnology from "@cospex/client/reverse/components/sections/UniqueTechnology";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const { t } = useTranslation();
  useTitle(t("home-page-title"));
  const { session } = useAuth();
  const navigate = useNavigate();

  const onTrack = () => {
    if (session) {
      navigate("/dashboard/history");
      return;
    }
    navigate("/payment");
  };

  return (
    <Box>
      <HeroWithSearch onTrack={onTrack} pageNumber={2} />
      <SocialProof />
      <Reviews />
      <HowDoesItWork />
      <Advantage />
      <Discover />
      <UniqueTechnology />
      <Pricing />
    </Box>
  );
}

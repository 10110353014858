import TextInput from "@cospex/client/forms/TextInput";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";

type ContactForm = {
  gender?: string;
  lastname?: string;
  firstname?: string;
  address?: string;
};

export default function PhoneInfo() {
  const { t } = useTranslation();

  const [success, setSuccess] = useState(false);
  const { id } = useParams("/phone-info/:id");

  const { control, handleSubmit, formState } = useForm<ContactForm>({
    mode: "onTouched",
    resolver: zodResolver(
      z.object({
        gender: z.string().optional(),
        firstname: z.string().optional(),
        lastname: z.string().optional(),
        address: z.string().optional(),
      })
    ),
  });

  const newTrackingMutation = useMutation({
    networkMode: "always",
    mutationFn: ({ gender, firstname, lastname, address }: ContactForm) => {
      const body = {
        gender,
        firstname,
        lastname,
        address,
      };
      return axios.put("/api/reverse/lookups/" + id, body);
    },
    onSuccess: () => {
      setSuccess(true);
    },
    onError: (data: any) => {
      const apiError = data.response?.data?.error;
    },
  });

  const onSubmit: SubmitHandler<ContactForm> = (data) => {
    console.log(data);
    newTrackingMutation.mutate(data);
  };

  return (
    <Stack
      sx={{
        margin: "4rem 0 10rem 0",
        alignItems: "center",
        gap: "2rem",
      }}
    >
      {success ? (
        <Stack sx={{ px: "1rem" }}>
          <Typography>{t("phone-info-success")}</Typography>
        </Stack>
      ) : (
        <>
          <Stack
            sx={{
              height: "111px",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "#EEEEEE",
            }}
          >
            <Typography variant="h3">{t("phone-info-transmit")}</Typography>
          </Stack>
          <Box
            component="form"
            sx={{
              width: "80%",
              maxWidth: "500px",
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Stack sx={{ gap: "1rem" }}>
              <Box>
                <Typography variant="h6">{t("phone-info-gender")}</Typography>
                <FormControl fullWidth>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <Select onChange={onChange} value={value}>
                        <MenuItem value="male">{t("phone-info-male")}</MenuItem>
                        <MenuItem value="female">
                          {t("phone-info-female")}
                        </MenuItem>
                      </Select>
                    )}
                    control={control}
                    name="gender"
                  />
                </FormControl>
              </Box>

              <Box>
                <Typography variant="h6">
                  {t("phone-info-first-name")}
                </Typography>
                <TextInput
                  formState={formState}
                  control={control}
                  name="firstname"
                  fullWidth
                />
              </Box>

              <Box>
                <Typography variant="h6">
                  {t("phone-info-last-name")}
                </Typography>
                <TextInput
                  formState={formState}
                  control={control}
                  name="lastname"
                  fullWidth
                />
              </Box>

              <Box>
                <Typography variant="h6">{t("phone-info-address")}</Typography>
                <TextInput
                  formState={formState}
                  control={control}
                  name="address"
                  fullWidth
                  multiline
                  sx={{ minHeight: "57px" }}
                />
              </Box>

              <Button type="submit" variant="contained" sx={{ color: "white" }}>
                {t("phone-info-submit")}
              </Button>
            </Stack>
          </Box>
        </>
      )}
    </Stack>
  );
}

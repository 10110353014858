import { API_PATH_UPDATE_PASSWORD } from "@cospex/client/constants";
import TextInput from "@cospex/client/forms/TextInput";
import useTranslation from "@cospex/client/hooks/useTranslation";
import {
  CenteredCardSubtitle,
  CenteredCardTitle,
} from "@cospex/client/reverse/components/CenteredCardTitle";
import { Label } from "@cospex/client/reverse/components/Typography";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Container, Stack, useTheme } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios, { isAxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

type ResetPasswordData = {
  firstPass: string;
  secondPass: string;
};

const validationSchema = z.object({
  firstPass: z.string().min(4, "update-pass-min"),
  secondPass: z.string().min(4, "update-pass-min"),
});

const UpdatePassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { handleSubmit, control, formState } = useForm<ResetPasswordData>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      firstPass: "",
      secondPass: "",
    },
  });
  const [error, setErrMsg] = useState("");
  const ref = useRef<HTMLDivElement>(null);
  const mutation = useMutation({
    mutationFn: (variables: { firstPass: string; secondPass: string }) => {
      return axios.post(
        API_PATH_UPDATE_PASSWORD,
        JSON.stringify({
          formData: {
            token: token,
            plainPassword: {
              first: variables.firstPass,
              second: variables.secondPass,
            },
          },
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess: () => {
      navigate("/login", { replace: true });
    },
    onError(error) {
      if (isAxiosError(error)) {
        setErrMsg(error.response?.data.error);
      }
    },
  });

  useEffect(() => {
    ref.current?.focus();
  }, []);

  const theme = useTheme();

  return (
    <Container>
      <Stack sx={{ alignItems: "center", margin: "5rem 0 10rem 0" }}>
        <Stack
          sx={{
            boxShadow: {
              xs: "",
              sm: `0px 10px 13px rgba(118, 145, 255, 0.4), 0px 8px 0px ${theme.palette.primary.main}`,
            },
            padding: "1.5rem",
            maxWidth: "914px",
            minWidth: { md: "562px" },
            borderRadius: "12px",
          }}
        >
          <Stack sx={{ alignItems: "center", padding: "2rem" }}>
            <Label>{t("update-pass-label")}</Label>
            <CenteredCardTitle text={t("update-pass-title")} />
            <CenteredCardSubtitle text={t("update-pass-subtitle")} />
            <Box
              component="form"
              onSubmit={handleSubmit((data: ResetPasswordData) =>
                mutation.mutate(data)
              )}
              sx={{ width: "100%" }}
            >
              <Stack spacing={2} sx={{ width: "100%" }}>
                <TextInput
                  ref={ref}
                  control={control}
                  name="firstPass"
                  formState={formState}
                  label="Password"
                  fullWidth
                />
                <TextInput
                  control={control}
                  name="secondPass"
                  formState={formState}
                  label="Reset password"
                  fullWidth
                />
                <LoadingButton
                  variant="contained"
                  loading={mutation.isLoading}
                  sx={{
                    color: "white",
                  }}
                  disableElevation
                  type="submit"
                >
                  <span>{t("update-pass-title")}</span>
                </LoadingButton>
                {error && <Alert severity="error">{error}</Alert>}
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

export default UpdatePassword;

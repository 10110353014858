import useAuth from "@cospex/client/hooks/useAuth";
import useTranslation from "@cospex/client/hooks/useTranslation";
import {
  FormatListBulleted,
  Logout,
  PermIdentity,
  Sms,
} from "@mui/icons-material";
import {
  Box,
  Container,
  Grid,
  Tab,
  Tabs,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

interface TabLinkProps {
  label: string;
  to: string;
  icon: any;
  value: string;
  onClick?: () => void;
}
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

function TabLink({ label, to, icon, onClick = noop }: TabLinkProps) {
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  return (
    <Tab
      icon={icon}
      iconPosition="start"
      label={sm ? label : ""}
      to={to}
      component={Link}
      sx={{
        whiteSpace: "nowrap",
        justifyContent: "flex-start",
        opacity: 1,
      }}
      onClick={onClick}
    />
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  page: string | undefined;
  value: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, page, value, ...other } = props;

  return (
    <Box
      sx={{
        width: "100%",
        p: {
          md: "0 0 3rem 3rem",
        },
      }}
      role="tabpanel"
      hidden={page !== value}
      id={`vertical-tabpanel-${value}`}
      aria-labelledby={`vertical-tab-${value}`}
      {...other}
    >
      {page === value && children}
    </Box>
  );
}

export default function DashboardLayout() {
  const { t } = useTranslation();
  const { session, signout } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  useEffect(() => {
    if (!session) {
      navigate("/");
    }
  }, [session]);

  const activeTab = pathname.split("/").slice(0, 3).join("/");

  return (
    <Box mt="3rem" mb="4rem">
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={3}>
            <Tabs
              value={activeTab}
              orientation={md ? "vertical" : "horizontal"}
              variant="scrollable"
            >
              <TabLink
                value={`/dashboard/search`}
                icon={<Sms />}
                label={t("dashboard-sms")}
                to="search"
              />
              <TabLink
                value={`/dashboard/history`}
                icon={<FormatListBulleted />}
                label={t("dashboard-history")}
                to="history"
              />
              <TabLink
                value={`/dashboard/account`}
                icon={<PermIdentity />}
                label={t("dashboard-my-account")}
                to="account"
              />
              <TabLink
                value={`/dashboard/logout`}
                icon={<Logout />}
                label={t("dashboard-logout")}
                to="/"
                onClick={() => signout()}
              />
              {/* <Box pl={2} pb={2} pt={4}>
              <LanguageSelect />
            </Box> */}
            </Tabs>
          </Grid>
          <Grid item xs={12} md={10} lg={9}>
            <TabPanel page={pathname} value={pathname}>
              <Outlet />
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

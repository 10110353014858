import { API_PATH_REQUEST_NEW_PASSWORD } from "@cospex/client/constants";
import TextInput from "@cospex/client/forms/TextInput";
import useTranslation from "@cospex/client/hooks/useTranslation";
import {
  CenteredCardSubtitle,
  CenteredCardTitle,
} from "@cospex/client/reverse/components/CenteredCardTitle";
import { Label } from "@cospex/client/reverse/components/Typography";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Container, Stack, useTheme } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

type ResetPasswordData = {
  email: string;
};

const ResetPassword = () => {
  const { t } = useTranslation();
  const emailRef = useRef<HTMLInputElement>(null);

  const validationSchema = z.object({
    email: z.string().email(t("reset-pass-email-required")).min(1),
  });

  const { control, handleSubmit, formState } = useForm<ResetPasswordData>({
    resolver: zodResolver(validationSchema),
  });
  const [resetSuccessful, setResetSuccessful] = useState(false);
  const [error, setError] = useState<null | string>();

  const mutation = useMutation({
    mutationFn: ({ email }: ResetPasswordData) => {
      return axios.post(
        API_PATH_REQUEST_NEW_PASSWORD,
        JSON.stringify({ email }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess: () => {
      setResetSuccessful(true);
    },
    onError(res: any) {
      const error = res.response?.data?.message || res.message;
      setError(error);
    },
  });

  useEffect(() => {
    emailRef.current?.focus();
  }, []);

  const theme = useTheme();
  return (
    <Container>
      <Stack sx={{ alignItems: "center", margin: "5rem 0 10rem 0" }}>
        <Stack
          sx={{
            boxShadow: {
              xs: "",
              sm: `0px 10px 13px rgba(118, 145, 255, 0.4), 0px 8px 0px ${theme.palette.primary.main}`,
            },
            padding: "1.5rem",
            maxWidth: "914px",
            minWidth: { md: "562px" },
            borderRadius: "12px",
          }}
        >
          <Stack sx={{ alignItems: "center", padding: "2rem" }}>
            <Label>{t("reset-pass-label")}</Label>
            <CenteredCardTitle text={t("reset-pass-title")} />
            <CenteredCardSubtitle text={t("reset-pass-subtitle")} />
            <Box
              component="form"
              onSubmit={handleSubmit((data: ResetPasswordData) =>
                mutation.mutate(data)
              )}
              sx={{ width: "100%" }}
            >
              <Stack spacing={2} sx={{ width: "100%" }}>
                <TextInput
                  control={control}
                  name="email"
                  formState={formState}
                  label="Email"
                  fullWidth
                />
                <LoadingButton loading={mutation.isLoading} type="submit">
                  {t("reset-pass-title")}
                </LoadingButton>
                {resetSuccessful && (
                  <Alert severity="success">{t("reset-pass-sent")}</Alert>
                )}
                {error && <Alert severity="error">{error}</Alert>}
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

export default ResetPassword;

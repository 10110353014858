import Counter from "@cospex/client/components/Counter";
import FeaturesList from "@cospex/client/components/FeaturesList";
import { getItemWithExpiry } from "@cospex/client/helpers";
import useTranslation from "@cospex/client/hooks/useTranslation";
import SearchPhone from "@cospex/client/reverse/SearchPhone";
import { Section } from "@cospex/client/reverse/components/Layout";
import PhoneGraphic from "@cospex/client/reverse/components/PhoneGraphic";
import TiltPlane from "@cospex/client/reverse/components/TiltPane";
import HeroImageA from "@cospex/client/reverse/img/hero-background.png";
import HeroImageB from "@cospex/client/reverse/img/hero-foreground.png";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";

interface HeroProps {
  number: string;
  country: string;
}

const Hero = ({ number, country }: HeroProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        marginX: "auto",
        aspectRatio: "812/864",
        maxWidth: 500,
      }}
    >
      <TiltPlane>
        <Stack sx={{ height: "85%", alignItems: "center" }}>
          <Box
            component="img"
            src={HeroImageA}
            alt="Hero Image"
            sx={{ width: "auto", height: "100%" }}
          />
        </Stack>
        <PhoneGraphic
          number={number}
          country={country}
          phoneImageSrc={HeroImageB}
        />
      </TiltPlane>
    </Box>
  );
};

interface HeroWithSearchProps {
  onTrack: () => void;
  pageNumber: 0 | 1 | 2 | 3;
}

export default function HeroWithSearch({
  onTrack,
  pageNumber,
}: HeroWithSearchProps) {
  const { t } = useTranslation();
  const [number, setNumber] = useState(
    getItemWithExpiry("onboardingNumber") || "06 XX XX XX XX"
  );
  const [country, setCountry] = useState("");

  const homeTitle = {
    part1: t(`home-title-${pageNumber}-1`),
    part2: t(`home-title-${pageNumber}-2`),
    part3: t(`home-title-${pageNumber}-3`),
  };

  return (
    <Box
      sx={{
        pt: "5vw",
      }}
    >
      <Section>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h1" gutterBottom>
                {homeTitle.part1}
                <Box component="span" sx={{ color: "primary.main" }}>
                  {homeTitle.part2}
                </Box>
                {homeTitle.part3}
              </Typography>
              <Typography sx={{ mb: 3, fontSize: 16 }}>
                {t("home-description")}
              </Typography>
              <SearchPhone
                onTrack={onTrack}
                onNumberChange={setNumber}
                onCountryChange={setCountry}
                business="reverse"
              />
              <FeaturesList
                featureKeys={[t("home-features-1"), t("home-features-2")]}
                sx={{ justifyContent: "left", gap: 1 }}
              />
              <Counter>{t("home-numbers-identified")}</Counter>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Hero number={number} country={country} />
            </Box>
          </Grid>
        </Grid>
      </Section>
    </Box>
  );
}

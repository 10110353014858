import { getToken } from "@cospex/client/context/AuthProvider";
import TextInput from "@cospex/client/forms/TextInput";
import useTranslation from "@cospex/client/hooks/useTranslation";
import PhoneGraphic from "@cospex/client/reverse/components/PhoneGraphic";
import modalAnimationData from "@cospex/client/reverse/img/lottie_tracking_anim.json";
import PhoneImage from "@cospex/client/reverse/img/phone.png";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowForward } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import intlTelInput, { Plugin } from "intl-tel-input";
import "intl-tel-input/build/js/utils";
import { Suspense, lazy, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

const SearchPhoneModal = lazy(
  () => import("@cospex/client/components/SearchPhoneModal")
);

let iti1: Plugin;

function usePhoneLocator() {
  const inputRef1 = useRef<any>(null);

  const result = useQuery({
    queryKey: ["ipapi"],
    enabled: false,
    queryFn: async () => {
      return await axios.get<{ country_code: string }>("https://ipapi.co/json");
    },
  });

  useEffect(() => {
    if (inputRef1.current) {
      iti1 = intlTelInput(inputRef1.current, {
        initialCountry: "auto",
        geoIpLookup: function (callback) {
          result
            .refetch()
            .then((result) => {
              if (result.data) {
                callback(result.data.data.country_code);
                return;
              }
              callback("fr");
            })
            .catch(() => {
              callback("fr");
            });
        },
        nationalMode: true,
        formatOnDisplay: true,
        preferredCountries: ["FR", "DE", "GB", "ES", "IT", "BE"],
      });
    }
  }, []);
  return [inputRef1];
}

type NameForm = {
  phone: string;
  firstname?: string;
  lastname?: string;
};

export function DashboardPhoneLocator() {
  const { t } = useTranslation();
  const [phoneLocRef] = usePhoneLocator();
  const navigate = useNavigate();
  const [number, onNumberChange] = useState("");
  const [searchPhoneModalOpen, setSearchPhoneModalOpen] = useState(false);
  const [mutationResponse, setMutationResponse] = useState<string | null>(null);

  const newTrackingMutation = useMutation({
    networkMode: "always",
    mutationFn: ({ phone }: NameForm) => {
      setSearchPhoneModalOpen(true);
      const intlphoneGeoloc = intlTelInputUtils.formatNumber(
        phone,
        iti1.getSelectedCountryData().iso2,
        intlTelInputUtils.numberFormat.INTERNATIONAL
      );
      const body = {
        phone: intlphoneGeoloc,
      };
      return axios.post("/api/reverse/lookups", body, {
        withCredentials: true,
        headers: { Authorization: "Bearer " + getToken() },
      });
    },
    onSuccess: (data) => {
      setMutationResponse(data.data?.id);
    },
    onError: (data: any) => {
      const apiError = data.response?.data?.error;
    },
  });

  const { control, handleSubmit, formState } = useForm<NameForm>({
    mode: "onTouched",
    defaultValues: {
      phone: "",
      firstname: "",
      lastname: "",
    },
    resolver: zodResolver(
      z.object({
        phone: z
          .string()
          .refine(
            (phone) => {
              return phone ? iti1.isValidNumber() : false;
            },
            {
              message: t("phone-form-tracking-invalid") as string,
            }
          )
          .optional(),
        firstname: z.string().optional(),
        lastname: z.string().optional(),
      })
    ),
  });

  const onAnimated = () => {
    setSearchPhoneModalOpen(false);
    if (mutationResponse !== "") {
      navigate(`/dashboard/history/${mutationResponse}`);
    }
  };

  const onSubmit: SubmitHandler<NameForm> = (data) => {
    newTrackingMutation.mutate(data);
  };

  return (
    <Box>
      <Typography variant="h3" color="primary.main" mb={4}>
        {t("dashboard-sms-title")}
      </Typography>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container sx={{ mb: 4 }}>
          <Grid item xs={12} md={6}>
            <Box mb={2}>
              <Typography variant="h6" gutterBottom>
                {t("dashboard-sms-confirm-phone")}
              </Typography>
            </Box>
            <Stack sx={{ px: { xs: "0.5rem", md: "2.5rem" } }}>
              <TextInput
                formState={formState}
                inputRef={phoneLocRef}
                control={control}
                name="phone"
                type="tel"
                onChangeCb={(e) => onNumberChange(e.target.value)}
                fullWidth
              />
              <LoadingButton
                sx={{ mt: 2, py: 1.3, color: "white" }}
                disableElevation
                type="submit"
                variant="contained"
                color="primary"
                endIcon={<ArrowForward />}
                loading={newTrackingMutation.isLoading}
              >
                <Typography textTransform="uppercase">
                  {t("phone-form-button")}
                </Typography>
              </LoadingButton>
            </Stack>
            <br />
            <Typography>{t("phone-form-note")}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              sx={{
                alignItems: "center",
                position: "relative",
                maxWidth: "40%",
                pb: "5rem",
                margin: "auto",
              }}
            >
              <PhoneGraphic
                number={number}
                phoneImageSrc={PhoneImage}
                textSize={24}
                numberSx={{
                  top: 84,
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Suspense>
        {searchPhoneModalOpen && (
          <SearchPhoneModal
            onAnimated={onAnimated}
            animationData={modalAnimationData}
          />
        )}
      </Suspense>
    </Box>
  );
}

import useTranslation from "@cospex/client/hooks/useTranslation";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  CircularProgress,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

interface IPhoneSearchAnimation {
  onAnimated: () => void;
  carrierLogo: string;
}
const PhoneSearchAnimation = ({
  onAnimated,
  carrierLogo,
}: IPhoneSearchAnimation) => {
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();

  const handleAnimationComplete = useCallback(() => {
    if (typeof onAnimated === "function") {
      setTimeout(onAnimated, 1500);
    }
  }, [onAnimated]);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(interval);
          handleAnimationComplete();
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 250);

    return () => {
      clearInterval(interval);
    };
  }, [handleAnimationComplete]);
  const iconContainerStyle = {
    width: 32,
    height: 32,
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const getIcon = (threshold: number) => {
    return (
      <Box sx={iconContainerStyle}>
        {progress >= threshold ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CircularProgress size={20} />
        )}
      </Box>
    );
  };
  return (
    <Box
      sx={{
        width: "100%",
        padding: 2,
      }}
    >
      <Stack
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        fontSize={16}
        sx={{
          mb: 1,
        }}
      >
        <Typography variant="h6">{t("phone-search-user-found")}</Typography>
        <Typography
          component="span"
          fontSize={16}
          color="textSecondary"
        >{`${Math.round(progress)}%`}</Typography>
      </Stack>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          borderRadius: 4,
          height: 24,
          mb: 2,
        }}
      />
      <Box
        sx={{
          "> .MuiBox-root:not(:first-child)": {
            borderTop: "1px solid #F0F0F0",
          },
          "> .MuiBox-root": {
            display: "flex",
            alignItems: "center",
            py: 1,
          },
        }}
      >
        <Box>
          <Typography sx={{ flexGrow: 1 }}>
            {t("phone-search-first-name")}
          </Typography>
          {getIcon(20)}
        </Box>
        <Box>
          <Typography sx={{ flexGrow: 1 }}>
            {t("phone-search-last-name")}
          </Typography>
          {getIcon(40)}
        </Box>
        <Box>
          <Typography sx={{ flexGrow: 1 }}>
            {t("phone-search-carrier")}
          </Typography>
          <Box sx={iconContainerStyle}>
            {progress >= 60 ? (
              carrierLogo ? (
                <Box
                  component="img"
                  src={carrierLogo}
                  alt={t("phone-search-carrier")}
                  width={20}
                />
              ) : (
                <CheckCircleIcon color="success" />
              )
            ) : (
              <CircularProgress size={20} />
            )}
          </Box>
        </Box>
        <Box>
          <Typography sx={{ flexGrow: 1 }}>
            {t("phone-search-gender")}
          </Typography>
          {getIcon(80)}
        </Box>
        <Box>
          <Typography sx={{ flexGrow: 1 }}>
            {t("phone-search-address")}
          </Typography>
          {getIcon(100)}
        </Box>
      </Box>
    </Box>
  );
};

export default PhoneSearchAnimation;

import { FloatingCard } from "@cospex/client/reverse/components/Layout";
import { DashboardPhoneLocator } from "@cospex/client/reverse/components/SearchPhoneDashboard";

export default function Search() {
  return (
    <FloatingCard sx={{ mb: 3 }}>
      <DashboardPhoneLocator />
    </FloatingCard>
  );
}

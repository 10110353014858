import useTranslation from "@cospex/client/hooks/useTranslation";
import { HomeCard } from "@cospex/client/reverse/components/Layout";
import ApplicationImage from "@cospex/client/reverse/img/application.svg";
import DeviceImage from "@cospex/client/reverse/img/device.svg";
import HandImage from "@cospex/client/reverse/img/hand.png";
import NetworkImage from "@cospex/client/reverse/img/network.svg";
import { Box, Stack, Typography } from "@mui/material";

export default function Advantage() {
  const { t } = useTranslation();
  const cardSx = {
    alignItems: "center",
    gap: 1,
    flexDirection: "row",
  };

  const renderHomeCard = (imgSrc: string, title: string, desc: string) => (
    <HomeCard
      imgSrc={imgSrc}
      title={title}
      desc={desc}
      sx={cardSx}
      variant="h4"
    />
  );

  return (
    <Box>
      <Stack
        sx={{
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
        }}
      >
        <Box flex="1 1 0">
          <Box
            component="img"
            height="100%"
            width="100%"
            src={HandImage}
            sx={{
              maxHeight: {
                xs: 300,
                md: "none",
              },
              objectFit: "cover",
            }}
          />
        </Box>
        <Stack
          flex="1 1 0"
          sx={{ justifyContent: "center", alignItems: "center", p: 4 }}
        >
          <Box
            sx={{
              maxWidth: 460,
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Typography
              variant="h3"
              sx={{
                w: "100%",
                textAlign: "center",
                pl: 1,
              }}
            >
              {t("home-globe-advantage-title")}
            </Typography>
            {renderHomeCard(
              DeviceImage,
              t("home-globe-phones-title"),
              t("home-globe-phones-desc")
            )}
            {renderHomeCard(
              NetworkImage,
              t("home-globe-operators-title"),
              t("home-globe-operators-desc")
            )}
            {renderHomeCard(
              ApplicationImage,
              t("home-globe-applications-title"),
              t("home-globe-applications-desc")
            )}
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}

import useAuth from "@cospex/client/hooks/useAuth";
import { Box, Container } from "@mui/material";

export default function Dashboard() {
  const { session } = useAuth();
  return (
    <Box>
      <Container>Welcome {session?.email}! This is the dashboard.</Container>
    </Box>
  );
}

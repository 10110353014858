import PhoneSearchAnimation from "@cospex/client/reverse/PhoneSearchAnimation";
import { Box, Modal } from "@mui/material";

interface ISearchPhoneModalProps {
  onAnimated: () => void;
  carrierLogo: string;
}

export default function SearchPhoneModal({
  onAnimated,
  carrierLogo,
}: ISearchPhoneModalProps) {
  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          maxWidth: 420,
          backgroundColor: "white",
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "100%",
          outline: "none",
          transform: "translate(-50%, -50%)",
          borderRadius: 2,
        }}
      >
        <PhoneSearchAnimation
          onAnimated={onAnimated}
          carrierLogo={carrierLogo}
        />
      </Box>
    </Modal>
  );
}

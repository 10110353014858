import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import { Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const SocialProof = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Grid
        container
        sx={{
          textAlign: "center",
          px: 2,
          py: 4,
          mb: {
            sm: 8,
            xs: 4,
          },
          gap: {
            xs: 6,
            sm: 0,
          },
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          borderRadius: 3,
          color: "white",
          backgroundColor: "#0F172A",
          ".MuiSvgIcon-root": {
            fontSize: 32,
            color: "primary.main",
            width: "100%",
          },
          ".MuiGrid-item": {
            display: "flex",
            flexDirection: "column",
            gap: 1,
          },
        }}
      >
        <Grid item xs={12} sm={4}>
          <Typography variant="h4">{t("social-proof-title-1")}</Typography>
          <Typography variant="subtitle1">
            {t("social-proof-description-1")}
          </Typography>
          <TravelExploreIcon />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h4">{t("social-proof-title-2")}</Typography>
          <Typography variant="subtitle1">
            {t("social-proof-description-2")}
          </Typography>
          <PeopleAltIcon />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h4">{t("social-proof-title-3")}</Typography>
          <Typography variant="subtitle1">
            {t("social-proof-description-3")}
          </Typography>
          <SentimentSatisfiedAltIcon />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SocialProof;

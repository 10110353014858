import { getToken } from "@cospex/client/context/AuthProvider";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { FloatingCard } from "@cospex/client/reverse/components/Layout";
import {
  Button,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

type History = {
  id: number;
  phone: string;
  found: boolean;
  searchDate: string;
};
type Response = {
  totalRequest: number;
  totalContactDetailsObtained: number;
  history: History[];
};
type SuccessStatus = {
  [key: number]: {
    status: "default" | "loading" | "success" | "error";
    message?: string;
  };
};

export default function History() {
  const { t } = useTranslation();
  const { data, isLoading, error } = useQuery({
    queryKey: ["dashboard-history"],
    queryFn: async () =>
      await axios.get<Response>("/api/reverse/lookups", {
        withCredentials: true,
        headers: { Authorization: "Bearer " + getToken() },
      }),
  });

  const [, setSuccessStatus] = useState<SuccessStatus>({});

  const mutation = useMutation({
    mutationFn: async (id: string) => {
      return await axios.post(
        "/api/reverse/sms",
        { id: id },
        {
          headers: { Authorization: "Bearer " + getToken() },
        }
      );
    },
    onSuccess: (_, id) => {
      setSuccessStatus((prev) => ({
        ...prev,
        [id]: { status: "success" },
      }));
    },
    onError: (error, id) => {
      if (error.response.data.error === "sms-max-sent") {
        setSuccessStatus((prev) => ({
          ...prev,
          [id]: { status: "error", message: t("sms-max-sent") },
        }));
        return;
      }
      setSuccessStatus((prev) => ({
        ...prev,
        [id]: {
          status: "error",
          message: t("dashboard-history-sms-sent-error"),
        },
      }));
    },
  });

  const handleButtonClick = async (id: string) => {
    setSuccessStatus((prev) => ({ ...prev, [id]: { status: "loading" } }));
    mutation.mutate(id);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  if (isLoading)
    return (
      <>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={150}
          sx={{ mb: 4 }}
        />
        <Skeleton
          variant="rectangular"
          width="100%"
          height={300}
          sx={{ mb: 4 }}
        />
      </>
    );
  if (error) return `An error has occurred: ${error}`;

  return (
    <>
      <FloatingCard sx={{ mb: 3 }}>
        <Typography variant="h3" color="primary.main" mb={2}>
          {t("dashboard-history-title")}
        </Typography>
        <Typography variant="body1" mb={3}>
          <strong>{t("dashboard-history-general-info")}</strong>
        </Typography>
        <Typography variant="body1">
          {t("dashboard-history-total-sms-sent")} {data?.data.totalRequest}
        </Typography>
        <Typography variant="body1">
          {t("dashboard-history-total-position-validated")}{" "}
          {data?.data.totalContactDetailsObtained}
        </Typography>
      </FloatingCard>
      <FloatingCard sx={{ mb: 3 }}>
        <Typography variant="h3" color="primary.main" mb={2}>
          {t("dashboard-history-yours")}
        </Typography>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t("dashboard-history-datetime")}</TableCell>
                <TableCell align="center">
                  {t("dashboard-history-status")}
                </TableCell>
                <TableCell align="center">
                  {t("dashboard-history-phonenumber")}
                </TableCell>
                <TableCell align="center">
                  {t("dashboard-history-action")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.data.history.map((row, i) => {
                let status = t("dashboard-history-sms-sent-pending");
                if (row.found) {
                  status = t("dashboard-history-position-enabled");
                }

                const index = i + 1;
                return (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Stack flexDirection="row" alignItems="center">
                        <Typography variant="body1" pr={2}>
                          <b>{index + "."}</b>
                        </Typography>
                        {row.searchDate}
                      </Stack>
                    </TableCell>
                    <TableCell align="center">{status}</TableCell>
                    <TableCell align="center">{row.phone}</TableCell>
                    <TableCell align="center">
                      <Button
                        to={`/dashboard/history/${row.id}`}
                        component={Link}
                      >
                        {t("dashboard-history-result-link")}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </FloatingCard>
    </>
  );
}

import Header from "@cospex/client/components/Header";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

import Footer from "../components/Footer";

export default function App() {
  return (
    <Box>
      <Header
        menu={{
          dashboardHref: "/dashboard/search",
        }}
      />
      <Outlet />
      <Footer />
    </Box>
  );
}

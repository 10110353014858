import { useCustomization } from "@cospex/client/hooks/useCustomization";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { HomeCard } from "@cospex/client/reverse/components/Layout";
import FirstImage from "@cospex/client/reverse/img/card-first.svg";
import SecondImage from "@cospex/client/reverse/img/card-second.svg";
import ThirdImage from "@cospex/client/reverse/img/card-third.svg";
import { Box, Container, Stack, Typography } from "@mui/material";

export default function HowDoesItWork() {
  const { t } = useTranslation();
  const cardSx = {
    flex: "1 1 0",
    alignItems: "center",
    rowGap: 2,
    "& h3": {
      mb: 2,
    },
  };
  const { logo } = useCustomization();
  return (
    <Box sx={{ backgroundColor: "#F2F4F5" }}>
      <Container
        sx={{
          px: 4,
          py: {
            xs: 4,
            sm: 6,
            md: 8,
          },
          gap: "4rem",
          textAlign: "center",
        }}
      >
        <Stack sx={{ alignItems: "center", rowGap: "1rem", mb: 4 }}>
          <Box
            sx={{
              backgroundImage: `url(${logo.src})`,
              backgroundRepeat: "no-repeat",
              width: 40,
              height: 40,
              paddingRight: "3rem",
            }}
          />
          <Typography color="primary.main" variant="h4">
            {t("how-does-it-work-title")}
          </Typography>
          <Typography>{t("how-does-it-work-desc")}</Typography>
        </Stack>
        <Stack
          sx={{
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            columnGap: 2,
            rowGap: 4,
            mb: {
              xs: 4,
              md: 8,
            },
          }}
        >
          <HomeCard
            imgSrc={FirstImage}
            title={t("how-does-list-one-title")}
            desc={t("how-does-list-one-desc")}
            variant="h3"
            sx={cardSx}
          />
          <HomeCard
            imgSrc={SecondImage}
            title={t("how-does-list-two-title")}
            desc={t("how-does-list-two-desc")}
            variant="h3"
            sx={cardSx}
          />
          <HomeCard
            imgSrc={ThirdImage}
            title={t("how-does-list-three-title")}
            desc={t("how-does-list-three-desc")}
            variant="h3"
            sx={cardSx}
          />
        </Stack>
        <Stack sx={{ alignItems: "center" }}>
          <Typography sx={{ width: "80%" }}>
            {t("how-does-it-work-note")}
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}

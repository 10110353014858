import useTranslation from "@cospex/client/hooks/useTranslation";
import { Box, SxProps } from "@mui/material";
import { Textfit } from "react-textfit";

interface PhoneProps {
  number: string;
  country?: string;
  phoneImageSrc: string;
  numberSx?: SxProps;
  textSize?: number;
}

export default function PhoneGraphic({
  number,
  country,
  phoneImageSrc,
  numberSx = {},
  textSize = 16,
}: PhoneProps) {
  return (
    <Box
      sx={{
        transform: "scale(1.2) translateY(12%)",
      }}
    >
      <Textfit
        mode="single"
        max={textSize}
        style={{
          display: "flex",
          color: "white",
          position: "absolute",
          top: "30%",
          width: "25%",
          height: "10px",
          left: "50%",
          zIndex: 20,
          transform: "translateX(-50%)",
          justifyContent: "center",
          ...numberSx,
        }}
      >
        {number}
      </Textfit>
      <Textfit
        mode="single"
        max={10}
        style={{
          display: "flex",
          color: "rgba(255, 255, 255, 0.4)",
          position: "absolute",
          top: "37%",
          width: "20%",
          left: "50%",
          height: "10px",
          zIndex: 20,
          transform: "translateX(-50%)",
          justifyContent: "center",
          ...numberSx,
        }}
      >
        {country}
      </Textfit>
      <Box
        component="img"
        src={phoneImageSrc}
        alt="Hero Image"
        sx={{ width: "100%", height: "100%" }}
      />
    </Box>
  );
}

import useTranslation from "@cospex/client/hooks/useTranslation";
import LocateCard from "@cospex/client/reverse/components/Locate";
import PrivacyWelcomeImg from "@cospex/client/reverse/img/discover-privacy.svg";
import SwiftWelcomeImg from "@cospex/client/reverse/img/discover-swift.svg";
import DiscoverWelcomeImg from "@cospex/client/reverse/img/discover-welcome.svg";
import { Box, Container, Stack, Typography } from "@mui/material";

export default function Discover() {
  const { t } = useTranslation();

  return (
    <Box sx={{ py: "3rem", backgroundColor: "#F2F4F5" }}>
      <Container>
        <Stack alignItems="center" mt="2rem">
          <Typography variant="h2" textAlign="center">
            {t("discover-title")}
          </Typography>
        </Stack>
        <Stack
          sx={{
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-evenly",
            columnGap: "2.5em",
            rowGap: "2.5em",
            paddingTop: "3rem",
          }}
        >
          <LocateCard
            src={DiscoverWelcomeImg}
            title={t("discover-welcome-title")}
            desc={t("discover-welcome-desc")}
          />
          <LocateCard
            src={SwiftWelcomeImg}
            title={t("discover-swift-title")}
            desc={t("discover-swift-desc")}
          />
          <LocateCard
            src={PrivacyWelcomeImg}
            title={t("discover-privacy-title")}
            desc={t("discover-privacy-desc")}
          />
        </Stack>
      </Container>
    </Box>
  );
}

import usePrices from "@cospex/client/hooks/usePrices";
import useTranslation from "@cospex/client/hooks/useTranslation";
import CircleArrow from "@cospex/client/reverse/img/circlearrow.svg";
import { TaskAlt } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { ShadowCard } from "./Layout";
import { List, StyledUL } from "./List";

const ListTask = ({ label }: { label: string }) => {
  return (
    <List
      startAdornment={<TaskAlt sx={{ color: "primary.main" }} />}
      label={label}
    />
  );
};

const PricingComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (location.pathname === "/pricing") {
      navigate("/");
      return;
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  const prices = usePrices();
  return (
    <Stack sx={{ alignItems: "center", my: "4rem" }}>
      <ShadowCard>
        <Stack sx={{ alignItems: "center", paddingBottom: "3rem" }}>
          <Typography variant="h2">{t("pricing-heading")}</Typography>
        </Stack>
        <Stack
          sx={{
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            justifyContent: "center",
            gap: "2rem",
            width: "100%",
          }}
        >
          <Stack
            sx={{
              flex: "1 1 0",
              alignItems: { xs: "left", sm: "center" },
              padding: "1rem",
              backgroundColor: "white",
              borderRadius: "12px",
            }}
          >
            <Typography variant="h4" textAlign="center">
              {t("pricing-title")}{" "}
              <Box component="span" sx={{ color: "primary.main" }}>
                {t("pricing-title-addendum")}
              </Box>
            </Typography>
            <StyledUL sx={{ textAlign: "left" }}>
              <ListTask label={t("pricing-list-one")} />
              <ListTask label={t("pricing-list-two")} />
              <ListTask label={t("pricing-list-three")} />
              <ListTask label={t("pricing-list-four")} />
              <ListTask label={t("pricing-list-five")} />
              <ListTask label={t("pricing-list-six")} />
              <ListTask label={t("pricing-list-seven")} />
              <ListTask label={t("pricing-list-eight")} />
            </StyledUL>
          </Stack>

          {/* Right Side */}
          <Stack
            sx={{
              flex: "1 1 0",
              alignItems: "center",
              rowGap: "1rem",
              padding: "1rem",
              py: "1.3rem",
              backgroundColor: "white",
              borderRadius: "12px",
            }}
          >
            <Stack
              sx={{
                rowGap: "1rem",
                position: "relative",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                src={CircleArrow}
                sx={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  fontSize: "3.5rem",
                  color: "primary.main",
                }}
              />
              <Stack
                sx={{
                  flexDirection: "row",
                  border: "1px solid",
                  borderColor: "primary.main",
                  borderRadius: "10px",
                  padding: "1rem",
                  width: "100%",
                  maxWidth: "346px",
                  height: "89px",
                  alignItems: "center",
                  backgroundColor: "white",
                }}
              >
                <Stack
                  sx={{
                    flex: "1 1 0",
                    color: "primary.main",
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h5">{t("pricing-48-hours")}</Typography>
                  <Typography variant="h5">
                    {t("pricing-48-trial-offer")}
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    flex: "1 1 0",
                    justifyContent: "center",
                    textAlign: "center",
                    color: "black",
                  }}
                >
                  <Typography variant="h5">{t("pricing-48")}</Typography>
                </Stack>
              </Stack>
              <Stack
                sx={{
                  flexDirection: "row",
                  border: "1px solid",
                  borderColor: "primary.main",
                  borderRadius: "10px",
                  padding: "1rem",
                  width: "100%",
                  maxWidth: "346px",
                  height: "89px",
                  alignItems: "center",
                  backgroundColor: "white",
                }}
              >
                <Stack
                  sx={{
                    flex: "1 1 0",
                    color: "primary.main",
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h5">
                    {t("pricing-48-subscription")}
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    flex: "1 1 0",
                    textAlign: "center",
                    color: "black",
                  }}
                >
                  <Typography variant="h5">
                    {t("pricing-48-rebill")}{" "}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              sx={{
                maxWidth: "346px",
                rowGap: "1rem",
              }}
            >
              <Typography>{t("pricing-48-desc")}</Typography>
              <Button variant="contained" onClick={handleButtonClick}>
                <Typography color="white">{t("pricing-48-start")}</Typography>
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </ShadowCard>
    </Stack>
  );
};

export default PricingComponent;

import { Box, Stack, Typography, useTheme } from "@mui/material";

export default function LocateCard({
  src,
  title,
  desc,
}: {
  src?: string;
  title: string;
  desc: string;
}) {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        boxShadow: `0px 10px 13px ${theme.palette.primary.main}55, 0px 4px 0px ${theme.palette.primary.main}`,
        borderRadius: "20px",
        alignItems: "center",
        flex: "1 1 0",
        padding: "1.5rem",
        rowGap: "1rem",
        textAlign: "center",
      }}
    >
      <Box component="img" src={src} height="61px" width="48px" />
      <Typography variant="h4">{title}</Typography>
      <Typography>{desc}</Typography>
    </Stack>
  );
}

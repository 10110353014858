import ReviewCarousel from "@cospex/client/components/ReviewCarousel";
import useTranslation from "@cospex/client/hooks/useTranslation";
import {
  Section,
  SectionSubtitle,
  SectionTitle,
} from "@cospex/client/reverse/components/Layout";

export default function Reviews() {
  const { t } = useTranslation();
  return (
    <Section filled>
      <SectionSubtitle>{t("home-testimonials-subtitle")}</SectionSubtitle>
      <SectionTitle>{t("home-testimonials-title")}</SectionTitle>
      <ReviewCarousel />
    </Section>
  );
}

import Pricing from "@cospex/client/reverse/components/PricingComponent";
import { Container, Stack } from "@mui/material";

export default function PricingSection() {
  return (
    <Container>
      <Stack sx={{ alignItems: "center" }}>
        <Pricing />
      </Stack>
    </Container>
  );
}

import { API_PATH_CONTACT } from "@cospex/client/constants";
import TextInput from "@cospex/client/forms/TextInput";
import useTranslation from "@cospex/client/hooks/useTranslation";
import ContactFormNumber from "@cospex/client/parcel/components/ContactFormNumber";
import { CenteredCardSuccessBox } from "@cospex/client/reverse/components/CenteredCardTitle";
import { ShadowCard } from "@cospex/client/reverse/components/Layout";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Container, Grid, Stack, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { z } from "zod";

import contactUsImg from "../img/contact-icon.png";

const validationSchema = z.object({
  email: z.string().nonempty("required").email("login-email-invalid"),
  firstname: z.string().nonempty("required"),
  lastname: z.string().nonempty("required"),
  message: z.string().nonempty("required"),
});

type ContactData = {
  email: string;
  firstname: string;
  lastname: string;
  message: string;
};

interface IContact {
  contactNumbers?: { [key: string]: string };
}

const Contact = ({ contactNumbers }: IContact) => {
  const { t } = useTranslation();
  const { control, handleSubmit, formState } = useForm<ContactData>({
    resolver: zodResolver(validationSchema),
  });
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { i18n } = useTranslation();
  const [messageSent, setMessageSent] = useState(false);
  const [error, setError] = useState("");

  const mutation = useMutation({
    mutationFn: async (variables: {
      firstname: string;
      lastname: string;
      email: string;
      message: string;
    }) => {
      const recaptcha = await recaptchaRef.current?.executeAsync();
      return axios.post(
        API_PATH_CONTACT,
        {
          formData: {
            firstname: variables.firstname,
            lastname: variables.lastname,
            email: variables.email,
            message: variables.message,
            "g-recaptcha-response": recaptcha,
          },
          language: i18n.language,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess: () => {
      setMessageSent(true);
    },
    onError(res: any) {
      const error = res.response?.data?.message || res.message;
      setError(error);
    },
  });

  return (
    <Container>
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ mt: { xs: "1rem", md: "4rem" }, mb: "6rem", rowGap: "2rem" }}
      >
        <Grid item xs={12} md={7}>
          <Stack
            sx={{ height: "100%", alignItems: { xs: "center", md: "end" } }}
          >
            <ShadowCard
              sx={{
                height: "inherit",
                width: { xs: "100%", md: "90%" },
                maxWidth: "658px",
              }}
            >
              <CenteredCardSuccessBox
                show={messageSent}
                textComponent={
                  <>
                    {t("contact-message-sent")}
                    <br />
                    {t("contact-message-wait")}
                  </>
                }
              />
              <Box
                component="form"
                onSubmit={handleSubmit((data: ContactData) =>
                  mutation.mutate(data)
                )}
              >
                <Stack
                  mt={2}
                  pb="2rem"
                  spacing={2}
                  sx={{ justifyContent: "space-around", height: "100%" }}
                >
                  <Stack alignItems="center" mb="2rem">
                    <Typography color="primary.main">
                      {t("contact-need-help")}
                    </Typography>
                    <Typography variant="h2">
                      {t("contact-contact-us")}
                    </Typography>
                  </Stack>
                  <TextInput
                    name="email"
                    label={t("contact-email")}
                    control={control}
                    formState={formState}
                    fullWidth
                  />
                  <TextInput
                    name="firstname"
                    label={t("contact-firstname")}
                    control={control}
                    formState={formState}
                    fullWidth
                  />
                  <TextInput
                    name="lastname"
                    label={t("contact-lastname")}
                    control={control}
                    formState={formState}
                    fullWidth
                  />

                  <TextInput
                    name="message"
                    label={t("contact-message")}
                    control={control}
                    formState={formState}
                    multiline
                    fullWidth
                    rows={4}
                  />
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    fullWidth
                    loading={mutation.isLoading}
                    sx={{ color: "#fff" }}
                  >
                    {t("contact-message-send")}
                  </LoadingButton>
                  {error && <Alert severity="error">{error}</Alert>}
                </Stack>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY}
                  size="invisible"
                  style={{
                    margin: 0,
                  }}
                />
              </Box>
            </ShadowCard>
          </Stack>
        </Grid>
        <Grid item xs={12} md={5}>
          <Stack
            sx={{
              height: "100%",
              alignItems: { xs: "center", md: "start" },
              textAlign: "center",
            }}
          >
            <ShadowCard
              sx={{
                height: "inherit",
                width: { xs: "100%", md: "80%" },
                maxWidth: "658px",
                alignItems: "center",
              }}
            >
              <Typography mt="2rem" mb="1.5rem">
                {t("contact-call-assistance-title")}
              </Typography>
              <Typography variant="h4">
                {t("contact-call-assistance-available")}
              </Typography>
              <Typography variant="body2" mb="1.5rem">
                {t("contact-call-assistance-availability")}
              </Typography>
              <ContactFormNumber contactNumbers={contactNumbers} />
              <Box component="img" src={contactUsImg} mt="2rem" />
            </ShadowCard>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Contact;

import useTranslation from "@cospex/client/hooks/useTranslation";
import { CheckCircle } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";

export default function PaymentStepper() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        pt: 2,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={1.5}
        mb={5}
        justifyContent="center"
        sx={{
          fontSize: "1.1rem",
          mb: {
            md: 4,
            sm: 3,
            xs: 2,
          },
        }}
      >
        <CheckCircle fontSize="large" sx={{ color: "primary.main" }} />
        <Box
          sx={{
            display: {
              md: "block",
              sm: "none",
              xs: "none",
            },
          }}
        >
          {t("payment-step-1")}
        </Box>
        <Box
          sx={{
            width: 50,
            height: "1px",
            background: "#E7EAEE",
            display: {
              md: "block",
              sm: "none",
              xs: "none",
            },
          }}
        />
        <Box
          sx={{
            width: 35,
            height: 35,
            border: "1px solid",
            borderColor: "primary.main",
            color: "primary.main",
            borderRadius: 35,
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          2
        </Box>
        <Box
          sx={{
            display: {
              md: "block",
              sm: "block",
              xs: "none",
            },
          }}
        >
          {t("payment-step-2")}
        </Box>
        <Box
          sx={{
            width: 50,
            height: "1px",
            background: "#E7EAEE",
            display: {
              md: "block",
              sm: "none",
              xs: "none",
            },
          }}
        />
        <Box
          sx={{
            width: 35,
            height: 35,
            border: "1px solid",
            borderColor: "primary.main",
            color: "primary.main",
            borderRadius: 35,
            opacity: 0.3,
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          3
        </Box>
        <span style={{ opacity: 0.3 }}>{t("payment-step-3")}</span>
      </Stack>
    </Box>
  );
}
